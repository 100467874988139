import { createSlice } from '@reduxjs/toolkit';

import {
  getGroupDetailThunk,
  leaveGroupThunk,
  updateGroupNameThunk,
  updateGroupGeofenceRadiusThunk,
  modifyUserRoleThunk,
  removeUserThunk,
} from '@/lib/group/groupAction';

const initialState = {
  detail: {},
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGeofence: (state, action) => {
      console.debug(action);
      // isGeofenceRadiusCheck
      state.detail.geofenceRadius = action.payload.geofenceRadius;
      state.detail.isGeofenceRadiusCheck = action.payload.isGeofenceRadiusCheck;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupDetailThunk.pending, (state, action) => {});
    builder.addCase(getGroupDetailThunk.fulfilled, (state, action) => {
      state.detail = action.payload.data;
    });
    builder.addCase(getGroupDetailThunk.rejected, (state, action) => {});

    builder.addCase(
      updateGroupGeofenceRadiusThunk.pending,
      (state, action) => {},
    );
    builder.addCase(
      updateGroupGeofenceRadiusThunk.fulfilled,
      (state, action) => {},
    );
    builder.addCase(
      updateGroupGeofenceRadiusThunk.rejected,
      (state, action) => {},
    );
  },
});

export const { setGeofence } = groupSlice.actions;

export default groupSlice.reducer;
