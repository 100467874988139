import { createSlice } from '@reduxjs/toolkit';

import {
  fetchRegisterThunk,
  fetchLoginThunk,
  fetchLogoutThunk,
  fetchUserSelfThunk,
  fetchQueryUserPhotoBriefByProjectIdThunk,
} from '@/lib/users/userAction';
import { usersApi } from './usersAPI';

interface InitialState {
  profile: {
    fullName: string;
    email: string;
    photoUrl: string;
    nameAbbreviation: string;
    userId: number;
    groups: any[];
    succeed: 'pending' | 'loading' | 'fulfilled' | 'failed' | 'rejected';
    isLoading: boolean;
  };
  registerProfile: {
    email: string;
    succeed: 'pending' | 'succeeded' | 'failed' | 'fulfilled' | 'rejected';
    isLoading: boolean;
    isSucceed: boolean;
  };
  forgetProfile: {
    email: string;
  };
  projectUsers: any;
  login: any;
  error: any;
  errorMessage: string;
}

const initialState: InitialState = {
  profile: {
    fullName: '',
    email: '',
    photoUrl: '/',
    nameAbbreviation: '',
    userId: 0,
    groups: [],
    succeed: 'pending', // 'pending' | 'loading' | 'succeeded' | 'failed'
    isLoading: false,
  },
  registerProfile: {
    email: '',
    succeed: 'pending',
    isLoading: false,
    isSucceed: false,
  },
  forgetProfile: {
    email: '',
  },
  projectUsers: {},
  login: {},
  error: {},
  errorMessage: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setStoreEmail: (state, action) => {
      state.registerProfile.email = action.payload;
    },
    setForgetStoreEmail: (state, action) => {
      state.forgetProfile.email = action.payload;
    },
    setFirebaseProfile: (state, action) => {
      const { email, displayName } = action.payload;
      state.profile.email = email;
      state.profile.fullName = displayName;
    },
    setFirebaseError: (state, action) => {
      state.errorMessage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRegisterThunk.pending, (state) => {
        state.registerProfile.succeed = 'pending';
      })
      .addCase(fetchRegisterThunk.fulfilled, (state, action) => {
        state.registerProfile.isSucceed = action.payload.isSucceed;
        if (state.registerProfile.isSucceed) {
          state.registerProfile = action.payload;
          state.registerProfile.succeed = 'fulfilled';
          state.registerProfile.isLoading = true;
        }
        state.errorMessage = action.payload.message;
      })
      .addCase(fetchRegisterThunk.rejected, (state, action) => {
        state.registerProfile.succeed = 'rejected';
        state.login = action.error;
      })
      .addCase(fetchLoginThunk.pending, (state) => {
        state.profile.succeed = 'pending';
      })
      .addCase(fetchLoginThunk.fulfilled, (state, action) => {
        state.login = action.payload;
      })
      .addCase(fetchLoginThunk.rejected, (state, action) => {
        state.profile.succeed = 'rejected';
        state.login = action.error;
      })
      .addCase(fetchUserSelfThunk.fulfilled, (state, action) => {
        state.profile = action?.payload;
        state.profile.succeed = 'fulfilled';
      })
      .addCase(fetchUserSelfThunk.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(fetchLogoutThunk.fulfilled, (state) => {
        state.profile = {
          ...initialState.profile,
          isLoading: false,
          succeed: 'pending',
        };
      })
      .addCase(fetchLogoutThunk.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(
        fetchQueryUserPhotoBriefByProjectIdThunk.fulfilled,
        (state, action) => {
          state.projectUsers = action.payload;
        },
      )
      .addMatcher(
        usersApi.endpoints.queryUserPhotoBriefByProjectId.matchFulfilled,
        (state, action) => {
          state.projectUsers = action.payload;
        },
      );
  },
});

export const {
  setStoreEmail,
  setForgetStoreEmail,
  setFirebaseProfile,
  setFirebaseError,
} = userSlice.actions;

export default userSlice.reducer;
