/**
 * 1 通常無訊息
 * 2 未歸類的異常狀況
 * 3 http status 401 未回傳 code 將導致錯誤
 * 範圍外以 "server 500" 作為回應
 * example:
 * errorCode(1) // Success
 *
 * @param {Number} code
 * @returns String
 */
export default function errorCode(code) {
  const errorMessages = {
    1: `Success`,
    2: `UnExcept Exception`,
    3: `UnAuthorized`,
    4: `InValidateParameter`,
    5: `ErrorRole`,
    6: `Sorry, we couldn't find the equipment you scanned. Please make sure that you are scanning the valid QR code.`,
    7: `This equipment belongs to another company. Please scan the QR code for your company’s equipment.`,
    8: `Subscription is ended.`,
    9: `Can not checkout with same plan and same seat counts.`,
    10: `Can not checkout again while subscription not expired.`,
    11: `Can not decrease seats count while subscription not expired.`,
    12: `Error checkout conditions.`,
  };
  return errorMessages[code] || `server 500`;
}
