import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getGroupDetail,
  leaveGroup,
  updateGroupName,
  updateGroupGeofenceRadius,
  modifyUserRole,
  removeUser,
} from './groupAPI';

const getGroupDetailThunk = createAsyncThunk(
  'group/fetchGetGroupDetail',
  async (groupId) => {
    const response = await getGroupDetail(groupId);
    return response.data;
  },
);

const leaveGroupThunk = createAsyncThunk('group/fetchLeaveGroup', async () => {
  const response = await leaveGroup();
  return response.data;
});

const updateGroupNameThunk = createAsyncThunk(
  'group/fetchUpdateGroupName',
  async () => {
    const response = await updateGroupName();
    return response.data;
  },
);

const updateGroupGeofenceRadiusThunk = createAsyncThunk(
  'group/fetchUpdateGroupGeofenceRadius',
  async (payload) => {
    const response = await updateGroupGeofenceRadius(payload);
    return response.data;
  },
);

const modifyUserRoleThunk = createAsyncThunk(
  'group/fetchModifyUserRole',
  async () => {
    const response = await modifyUserRole();
    return response.data;
  },
);

const removeUserThunk = createAsyncThunk('group/fetchRemoveUser', async () => {
  const response = await removeUser();
  return response.data;
});

export {
  getGroupDetailThunk,
  leaveGroupThunk,
  updateGroupNameThunk,
  updateGroupGeofenceRadiusThunk,
  modifyUserRoleThunk,
  removeUserThunk,
};
