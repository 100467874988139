
import { createSlice } from "@reduxjs/toolkit";


/**
* import React from 'react';
* import { useDispatch, useSelector } from 'react-redux';
* import { toggleDialog } from '../features/dialogs/dialogsSlice';
*
* const MyComponent = () => {
*   const dispatch = useDispatch();
*   const isTaskDialogOpen = useSelector((state) => state.dialogs['taskDialog']);
*
*   return (
*     <div>
*       <button onClick={() => dispatch(toggleDialog('taskDialog'))}>
*         Toggle Task Dialog
*       </button>
*       {isTaskDialogOpen && <div>Task Dialog is Open</div>}
*     </div>
*   );
* };
**/

const initialState = {}

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openDialog:(state, action) => {
      state[action.payload] = true
    },
    closeDialog:(state, action) => {
      state[action.payload] = false
    },
    toggleDialog:(state, action) => {
      state[action.payload] = !state[action.payload]
    }
  }
})

export const {openDialog, closeDialog, toggleDialog} = dialogsSlice.actions

export default dialogsSlice.reducer