import { createSlice } from '@reduxjs/toolkit';
import { activityAPI } from './activityAPI';

interface InitialState {
  data: any;
  comments: any;
  history: any;
  historyDetail: any;
  records: any;
  record: any;
}

const initialState: InitialState = {
  data: {},
  comments: [],
  history: [],
  historyDetail: [],
  records: [],
  record: {},
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resetState: () => initialState,
    removeRecord: (state, action) => {
      state.records.forEach((element) => {
        element.records = element.records.filter(
          (v) => v.activityRecordId !== action.payload.activityRecordId,
        );
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      activityAPI.endpoints.GetActivityRecordsByActivityId.matchFulfilled,
      (state, action) => {
        state.records = action.payload;
      },
    );
    builder.addMatcher(
      activityAPI.endpoints.GetActivityRecord.matchFulfilled,
      (state, action) => {
        state.record = action.payload;
      },
    );
    builder.addMatcher(
      activityAPI.endpoints.GetActivityHistoryDetailWithSquash.matchFulfilled,
      (state, action) => {
        state.historyDetail = action.payload;
      },
    );
    builder.addMatcher(
      activityAPI.endpoints.GetActivityDetail.matchFulfilled,
      (state, action) => {
        state.data = action.payload;
      },
    );
    builder.addMatcher(
      activityAPI.endpoints.GetActivityComments.matchFulfilled,
      (state, action) => {
        state.comments = action.payload;
      },
    );
  },
});

export const { removeRecord } = activitySlice.actions;

export default activitySlice.reducer;
