import { ApiPhoto } from '@/interface/interface';
import request from '@/utils/request';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';

const URL = '/api/user';

type NewsfeedDetail = {
  projectNewsfeedId: number;
  projectId: number;
  projectName: string;
  projectColor: string;
  activityId: number;
  activityName: string;
  date: Date;
  newsTemplateId: number;
  photoCount: number;
  createdUserName: string;
  photos: ApiPhoto[];
};

export function QueryNewsfeed(groupId) {
  return request({
    url: `${URL}/QueryNewsfeed?GroupId=${groupId}`,
    method: 'GET',
  });
}

export function GetNewsfeedDetail(newsfeedId) {
  return request({
    url: `${URL}/GetNewsfeedDetail?newsfeedId=${newsfeedId}`,
    method: 'GET',
  });
}

export async function queryNewsfeedAndWeeklyReportWithPaged(params) {
  const urlParams = new URLSearchParams(params).toString();
  return request(`${URL}/queryNewsfeedAndWeeklyReportWithPaged?${urlParams}`);
}

interface NewsfeedtWithPagedParams {
  groupId: number;
  pageNumber: number;
  pageCount: number;
}

export const newsfeedApi = createApi({
  reducerPath: 'newsfeedApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Newsfeed'],
  endpoints: (builder) => ({
    queryNewsfeedAndWeeklyReportWithPaged: builder.mutation<
      any,
      NewsfeedtWithPagedParams
    >({
      query: ({ groupId, pageNumber, pageCount }) => ({
        url: `/api/user/queryNewsfeedAndWeeklyReportWithPaged`,
        method: 'GET',
        params: {
          groupId,
          pageNumber,
          pageCount,
        },
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
      // forceRefetch: ({ currentArg, previousArg }) => {
      //   return currentArg?.pageNumber !== previousArg?.pageNumber;
      // },
    }),
  }),
});

export const { useQueryNewsfeedAndWeeklyReportWithPagedMutation } = newsfeedApi;
