import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  QueryNewsfeed,
  GetNewsfeedDetail,
  queryNewsfeedAndWeeklyReportWithPaged,
} from './newsfeedAPI';

export const QueryNewsfeedThunk = createAsyncThunk(
  'news/QueryNewsfeed',
  async (GroupId) => {
    const res = await QueryNewsfeed(GroupId);
    return res.data.data;
  },
);

export const GetNewsfeedDetailThunk = createAsyncThunk(
  'news/GetNewsfeedDetail',
  async (newsfeedId) => {
    const res = await GetNewsfeedDetail(newsfeedId);
    return res.data.data;
  },
);

export const queryNewsfeedAndWeeklyReportWithPagedThunk = createAsyncThunk(
  'news/queryNewsfeedAndWeeklyReportWithPaged',
  async (newsfeedId) => {
    const res = await queryNewsfeedAndWeeklyReportWithPaged(newsfeedId);
    return res.data.data;
  },
);
