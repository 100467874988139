import { createApi } from '@reduxjs/toolkit/query/react';
import { NotifyMessageResponse } from './interface';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { ApiResponse } from '@/interface/interface';

const URL = '/api/NotifyMessage';

export const notifyMessageApi = createApi({
  reducerPath: 'notifyMessageApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['NotifyMessage'],
  endpoints: (builder) => ({
    getNotifyMessagesByFilter: builder.query<NotifyMessageResponse, any>({
      query: (data) => ({
        url: `${URL}/GetNotifyMessagesByFilter`,
        method: 'GET',
        data,
      }),
      transformResponse: (response: any) => {
        // console.log(response.data.data);
        return response.data;
      },
      providesTags: (result, error, arg) => [{ type: 'NotifyMessage' }],
    }),
    getNotifyMessagesCountByFilter: builder.query<ApiResponse<number>, any>({
      query: (data) => ({
        url: `${URL}/GetNotifyMessagesCountByFilter`,
        method: 'GET',
        data,
      }),
      transformResponse: (response: any) => {
        // console.log(response.data.data);
        return response.data;
      },
    }),
    updateNotifyMessageAllIsRead: builder.mutation<ApiResponse<any>, any>({
      query: (params) => ({
        url: `${URL}/UpdateNotifyMessageAllIsRead`,
        method: 'PUT',
        params: params,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'NotifyMessage' }],
    }),
    updateNotifyMessageIsRead: builder.mutation<ApiResponse<any>, any>({
      query: (data) => ({
        url: `${URL}/UpdateNotifyMessageIsRead`,
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'NotifyMessage' }],
    }),
  }),
});

export const {
  useGetNotifyMessagesByFilterQuery,
  useGetNotifyMessagesCountByFilterQuery,
  useUpdateNotifyMessageAllIsReadMutation,
  useUpdateNotifyMessageIsReadMutation,
} = notifyMessageApi;
