import request from '@/utils/request';

const URL = '/api/group';

function getGroupDetail(params) {
  return request(`${URL}/GetGroupDetail?GroupId=${params}`);
}

function leaveGroup(params) {
  return request(`${URL}/Leave`, {
    method: 'POST',
    data: { GroupId: params },
  });
}

function updateGroupName(params) {
  return request(`${URL}/UpdateGroupName`, {
    method: 'PUT',
    data: params,
  });
}

function updateGroupGeofenceRadius(params) {
  return request(`${URL}/UpdateGroupGeofenceRadius`, {
    method: 'PUT',
    data: params,
  });
}

function modifyUserRole(params) {
  return request(`${URL}/ModifyUserRole`, {
    method: 'PUT',
    data: params,
  });
}

function removeUser(params) {
  return request(`${URL}/RemoveUser`, {
    method: 'POST',
    data: params,
  });
}

export {
  getGroupDetail,
  leaveGroup,
  updateGroupName,
  updateGroupGeofenceRadius,
  modifyUserRole,
  removeUser,
};
