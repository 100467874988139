import request from '@/utils/request';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import {
  Equipment,
  EquipmentAttendanceRecordDetail,
  EquipmentDetailResponse,
  EquipmentHistory,
  EquipmentHourlyReport,
  EquipmentLocationRecord,
  EquipmentLocationRecordData,
  EquipmentResponse,
  QueryEquipmentByFilterRequest,
  QueryEquipmentLocationRecordByPageFilterRequest,
  QueryEquipmentRequest,
  UpdateEquipmentAttendanceRecordRequest,
  UpdateEquipmentStatusRequest,
} from './interface';
import BaseToast from '@/components/base/BaseToast/BaseToast';

const URL = '/api/Equipment';

export function QueryCheckInEquipments(data) {
  return request({
    url: `/api/Equipment/QueryCheckInEquipments`,
    method: 'POST',
    data: data,
  });
}

interface QueryCheckInEquipmentsPayload {
  projectId: number;
  date: string;
}

export const equipmentApi = createApi({
  reducerPath: 'equipmentApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Equipment', 'EquipmentDetail', 'EquipmentRecord'],
  endpoints: (builder) => ({
    QueryCheckInEquipments: builder.mutation<
      any,
      QueryCheckInEquipmentsPayload
    >({
      query: (data) => ({
        url: '/api/Equipment/QueryCheckInEquipments',
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      // invalidatesTags: ['Equipment'],
    }),
    queryEquipmentsByPageFilter: builder.query<
      EquipmentResponse,
      QueryEquipmentRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/QueryEquipmentsByPageFilter',
        method: 'POST',
        data,
      }),
      providesTags: ['Equipment'],
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.Keyword !== previousArg?.Keyword ||
          currentArg?.GroupId !== previousArg?.GroupId
        );
      },
      transformResponse: (response) => response.data.data,
    }),
    queryEquipmentsByFilter: builder.query<
      Equipment[],
      QueryEquipmentByFilterRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/QueryEquipmentsByFilter',
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      transformResponse: (response) => response.data.data,
    }),
    queryEquipmentLocationRecordByPageFilter: builder.query<
      EquipmentLocationRecord,
      QueryEquipmentLocationRecordByPageFilterRequest
    >({
      query: (params) => ({
        url: '/api/Equipment/QueryEquipmentLocationRecordByPageFilter',
        method: 'GET',
        params,
      }),
      providesTags: ['EquipmentRecord'],
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.PageNumber !== previousArg?.PageNumber;
      },
      // merge: (currentCacheData, responseData) => {
      //   const newData = responseData.recordPageData.data.filter(
      //     (newItem) =>
      //       !currentCacheData.recordPageData.data.some(
      //         (item) =>
      //           item.equipmentAttendanceRecordId ===
      //           newItem.equipmentAttendanceRecordId,
      //       ),
      //   );

      //   currentCacheData.recordPageData.data = [
      //     ...currentCacheData.recordPageData.data,

      //     ...newData,
      //   ].sort(
      //     (a, b) =>
      //       (b.equipmentAttendanceRecordId ?? 0) -
      //       (a.equipmentAttendanceRecordId ?? 0),
      //   );
      //   currentCacheData.recordPageData.currentPageCount =
      //     responseData.recordPageData.currentPageCount;

      //   currentCacheData.recordPageData.pageCount =
      //     responseData.recordPageData.pageCount;

      //   currentCacheData.recordPageData.totalCount =
      //     responseData.recordPageData.totalCount;

      //   currentCacheData.recordPageData.pageNumber =
      //     responseData.recordPageData.pageNumber;

      //   currentCacheData.recordPageData.totalPageCount =
      //     responseData.recordPageData.totalPageCount;

      //   currentCacheData.users = responseData.users;
      // },
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentDetail: builder.query<EquipmentDetailResponse, number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/GetEquipmentDetail',
        method: 'GET',
        params: { equipmentId },
      }),
      providesTags: ['EquipmentDetail'],
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentAttendanceRecords: builder.query<any, number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/GetEquipmentAttendanceRecords',
        method: 'GET',
        params: { equipmentId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentHistories: builder.query<EquipmentHistory[], number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/GetEquipmentHistories',
        method: 'GET',
        params: { equipmentId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentHourlyReport: builder.query<
      EquipmentHourlyReport[],
      { equipmentId: number; year: number }
    >({
      query: ({ equipmentId, year }) => ({
        url: '/api/Equipment/GetEquipmentHourlyReport',
        method: 'GET',
        params: { equipmentId, year },
      }),
      transformResponse: (response) => response.data.data,
    }),
    getEquipmentAttendanceRecordDetail: builder.query<
      EquipmentAttendanceRecordDetail,
      number
    >({
      query: (equipmentAttendanceRecordId) => ({
        url: '/api/Equipment/GetEquipmentAttendanceRecordDetail',
        method: 'GET',
        params: { equipmentAttendanceRecordId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    createEquipment: builder.mutation<any, FormData>({
      query: (data) => ({
        url: '/api/Equipment/CreateEquipment',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Equipment'],
    }),
    updateEquipment: builder.mutation<any, FormData>({
      query: (data) => ({
        url: '/api/Equipment/UpdateEquipment',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Equipment'],
    }),
    duplicateEquipment: builder.mutation<any, number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/DuplicateEquipment',
        method: 'POST',
        data: { EquipmentId: equipmentId },
      }),
      invalidatesTags: ['Equipment'],
    }),
    deleteEquipment: builder.mutation<any, number>({
      query: (equipmentId) => ({
        url: '/api/Equipment/DeleteEquipment',
        method: 'DELETE',
        data: { EquipmentId: equipmentId },
      }),
      invalidatesTags: ['Equipment'],
    }),
    updateEquipmentStatus: builder.mutation<any, UpdateEquipmentStatusRequest>({
      query: (data) => ({
        url: '/api/Equipment/UpdateEquipmentStatus',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Equipment', 'EquipmentDetail'],
    }),
    updateEquipmentAttendanceRecord: builder.mutation<
      any,
      UpdateEquipmentAttendanceRecordRequest
    >({
      query: (data) => ({
        url: '/api/Equipment/UpdateEquipmentAttendanceRecord',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['EquipmentRecord'],
    }),
    deleteEquipmentAttendanceRecord: builder.mutation<any, number>({
      query: (equipmentAttendanceRecordId) => ({
        url: '/api/Equipment/DeleteEquipmentAttendanceRecord',
        method: 'DELETE',
        data: { EquipmentAttendanceRecordId: equipmentAttendanceRecordId },
      }),
    }),
  }),
});

export const {
  useQueryCheckInEquipmentsMutation,
  useQueryEquipmentsByPageFilterQuery,
  useQueryEquipmentLocationRecordByPageFilterQuery,
  useCreateEquipmentMutation,
  useGetEquipmentDetailQuery,
  useGetEquipmentAttendanceRecordsQuery,
  useGetEquipmentHistoriesQuery,
  useGetEquipmentHourlyReportQuery,
  useQueryEquipmentsByFilterQuery,
  useUpdateEquipmentMutation,
  useDuplicateEquipmentMutation,
  useDeleteEquipmentMutation,
  useUpdateEquipmentStatusMutation,
  useUpdateEquipmentAttendanceRecordMutation,
  useDeleteEquipmentAttendanceRecordMutation,
  useGetEquipmentAttendanceRecordDetailQuery,
} = equipmentApi;
