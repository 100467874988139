import { createSlice } from '@reduxjs/toolkit';
import { scheduleApi } from './scheduleAPI';
import { Contents, Projects, TaskLists } from './interface';
import { UserBriefPhotoViewModel } from '../taskList/interface';
import { addDays, endOfDay, formatISO, startOfDay, subDays } from 'date-fns';

interface InitialStateProps {
  scheduleStatus: 'day' | 'week';
  currentDate: string;
  contents: Contents[];
  users: any[];
  projects: Projects[];
  taskLists: TaskLists[];
  selectedProjectId: number;
}

const initialState: InitialStateProps = {
  scheduleStatus: 'day',
  currentDate: formatISO(startOfDay(new Date()), {
    representation: 'complete',
  }),
  contents: [
    {
      date: '',
      dateString: '',
      count: 0,
      contents: [
        {
          projectId: 0,
          count: 0,
          contents: [
            {
              taskListId: 0,
              count: 0,
              taskListSubTasks: [
                {
                  id: 0,
                  projectId: 0,
                  taskListId: 0,
                  title: '',
                  note: '',
                  isAllDay: true,
                  from: '',
                  to: '',
                  status: 1,
                  assignUsers: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  users: [],
  projects: [],
  taskLists: [],
  selectedProjectId: 0,
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setScheduleStatus: (state, action) => {
      state.scheduleStatus = action.payload;
    },
    setIncrementDate: (state) => {
      console.debug('setIncrementDate', state.currentDate);
      const newDate = addDays(new Date(state.currentDate), 1);
      state.currentDate = formatISO(startOfDay(newDate), {
        representation: 'complete',
      });
    },
    setDecrementDate: (state) => {
      console.debug('setDecrementDate', state.currentDate);
      const newDate = subDays(new Date(state.currentDate), 1);
      state.currentDate = formatISO(startOfDay(newDate), {
        representation: 'complete',
      });
    },
    setCurrentDate: (state) => {
      state.currentDate = formatISO(startOfDay(new Date()), {
        representation: 'complete',
      });
    },
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      scheduleApi.endpoints.querySchedule.matchFulfilled,
      (state, action) => {
        state.contents = action.payload.contents;
        state.projects = action.payload.projects;
        state.users = action.payload.users;
        state.taskLists = action.payload.taskLists;
      },
    );
  },
});

export const {
  setScheduleStatus,
  setIncrementDate,
  setDecrementDate,
  setCurrentDate,
  setSelectedProjectId,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
