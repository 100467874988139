export const DATE_RANGE = {
  ONE_DAY: 1,
  ONE_WEEK: 7,
  ONE_MONTH: 30,
  TWO_MONTHS: 60,
  THREE_MONTHS: 90,
  FOUR_MONTHS: 120,
  FIVE_MONTHS: 150,
  SIX_MONTHS: 180,
  ONE_YEAR: 365,
};

export const ROWS_PER_PAGE = 15;
