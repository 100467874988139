import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { format, parseISO } from 'date-fns';
import { Option } from '@/components/base/BaseMenu/BaseMenu';
import { AttendanceRecords, RecordTypes } from './interface';

const URL = '/api/Clock';

export const clockApi = createApi({
  reducerPath: 'clockAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAttendanceRecordTypes: builder.query<RecordTypes[], any>({
      query: () => ({
        url: `${URL}/GetAttendanceRecordTypes`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
    getQuerySelfAttendanceRecordsByFilterReturnViewModel: builder.query<
      AttendanceRecords,
      any
    >({
      query: (data) => ({
        url: `${URL}/QuerySelfAttendanceRecordsByFilterReturnViewModel`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
  }),
});

export const {
  useGetAttendanceRecordTypesQuery,
  useGetQuerySelfAttendanceRecordsByFilterReturnViewModelQuery,
} = clockApi;
