import { createApi } from '@reduxjs/toolkit/query/react';
import {
  PinProjectProps,
  ProjectDetail,
  ProjectDetailResponse,
  SelfProjects,
  SelfProjectsByFilter,
  SelfProjectsByFilterResponse,
  SelfProjectsResponse,
  UpdateProjectDetailRequestParameters,
} from './Interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSelfProjects: builder.query<SelfProjects[], number>({
      query: (groupId) => ({
        url: `/api/project/GetSelfProjects`,
        method: 'GET',
        params: { GroupId: groupId },
      }),
      transformResponse: (response: SelfProjectsResponse) => response.data.data,
    }),
    QueryProjectByFilter: builder.query<SelfProjectsByFilter[], number>({
      query: (projectId) => ({
        url: `/api/Project/QueryProjectByFilter`,
        method: 'GET',
        params: { id: projectId },
      }),
      transformResponse: (response: SelfProjectsByFilterResponse) =>
        response.data.data,
    }),
    getProjectDetail: builder.query<ProjectDetail, number>({
      query: (projectId) => ({
        url: `/api/Project/GetProjectDetail`,
        method: 'GET',
        params: { projectId: projectId },
      }),
      transformResponse: (response: ProjectDetailResponse) =>
        response.data.data,
    }),
    CreateProject: builder.mutation<any, any>({
      query: ({ name, address, startDate, endDate, groupId }) => ({
        url: `/api/Project/CreateProject`,
        method: 'POST',
        data: { name, address, startDate, endDate, groupId },
      }),
      transformResponse: (response: ProjectDetailResponse) =>
        response.data.data,
    }),
    UpdateProjectDetail: builder.mutation<
      UpdateProjectDetailRequestParameters,
      any
    >({
      query: (data) => ({
        url: `/api/Project/UpdateProjectDetail`,
        method: 'PUT',
        data: data,
      }),
      transformResponse: (response: ProjectDetailResponse) =>
        response.data.data,
    }),
    PinProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/PinProject`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    UnPinProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/UnPinProject`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const {
  useGetSelfProjectsQuery,
  useQueryProjectByFilterQuery,
  useGetProjectDetailQuery,
  useCreateProjectMutation,
  useUpdateProjectDetailMutation,
  usePinProjectMutation,
  useUnPinProjectMutation,
} = projectsApi;
